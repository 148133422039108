const navbar = document.getElementById('navbar-mobile');
//
if (navbar) {
  const toggleScrolledNavbar = () => {
    const st = window.scrollY;

    if (st > 200) {
      navbar.classList.add('isScrolled');
    } else {
      navbar.classList.remove('isScrolled');
    }
  }

  toggleScrolledNavbar();

  window.addEventListener('scroll', toggleScrolledNavbar);
}

const navToggle = document.getElementById('nav-toggle');

if (navToggle) {
  const nav = document.getElementById('mobile-nav');
  let isOpen = false;

  const onNavToggle = (e) => {
    e.preventDefault();

    if (isOpen) {
      nav.style.height = 0;
      isOpen = false;
    } else {
      isOpen = true;
      nav.style.height = parseInt(nav.scrollHeight) + 'px';
    }

  }

  navToggle.addEventListener('click', onNavToggle);
}
