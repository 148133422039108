const navbar = document.getElementById('navbar');
//
if (navbar) {
  const toggleScrolledNavbar = () => {
    const st = window.scrollY;

    if (st > 200) {
      navbar.classList.add('isScrolled');
    } else {
      navbar.classList.remove('isScrolled');
    }
  }

  toggleScrolledNavbar();

  window.addEventListener('scroll', toggleScrolledNavbar);

  const backdrop = document.getElementById('nav-backdrop');
  const pageOverlay = document.getElementById('nav-page-overlay');
  const navLogo = navbar.querySelector('.Navbar-item--logo');

  let subHeight = 0;
  let backdropHeight = 0;
  const navHeight = navbar.offsetHeight;
  const mainNavLinks = navbar.querySelectorAll('[data-parent]');

  const setBackdropHeight = () => {
    backdropHeight = 0;

    if (subHeight !== 0) {
      backdropHeight = navHeight + subHeight;
    }

    backdrop.style.height = `${backdropHeight}px`;
  };

  const onNavbarMouseMove = (e) => {
    let sub;

    const closestSub = e.target.closest('.Navbar-subMenu');

    let parentId;
    if (!closestSub) {
      parentId = e.target.getAttribute('data-parent');
      sub = navbar.querySelector(`[data-sub="${parentId}"]`);
    } else {
      sub = closestSub;
      parentId = sub.getAttribute('data-sub');
    }

    if (sub) {
      const par = navbar.querySelector(`[data-parent="${parentId}"]`);

      mainNavLinks.forEach((link) => {
        link.classList.remove('isHovered');
      });
      par.classList.add('isHovered');

      subHeight = sub.offsetHeight;
      pageOverlay.classList.add('isActive');
    } else {
      mainNavLinks.forEach((link) => {
        link.classList.remove('isHovered');
      });
      pageOverlay.classList.remove('isActive');
      subHeight = 0;
    }

    setBackdropHeight();
  };

  navbar.addEventListener('mousemove', onNavbarMouseMove);
}
