const items = document.querySelectorAll('.TeamList-item');

if (items && items.length > 0) {
  const imgEl = document.querySelector('.TeamList-image');
  items.forEach((item) => {
    item.addEventListener('mouseenter', () => {
      const imageUrl = item.getAttribute('data-image');

      if (imageUrl && imageUrl !== '') {
        imgEl.style.backgroundImage = `url(${imageUrl})`;
      }
    })
  });
}
