const wrapper = document.querySelector('.Timeline-wrapper');
const toggleButton = document.querySelector('.Timeline-toggle');

if (wrapper && toggleButton) {
  toggleButton.addEventListener('click', (e) => {
    e.preventDefault();

    wrapper.classList.remove('isHidden');
  });
}
