const docs = document.querySelector('.Documents');

if (docs) {
  const wrappers = document.querySelectorAll('.Documents-wrapper');

  wrappers.forEach((wrapper) => {
    const handle = wrapper.querySelector('.Documents-category');
    const container = wrapper.querySelector('.Documents-container');
    handle.addEventListener('click', (e) => {
      wrapper.classList.toggle('isOpen');
      if (wrapper.classList.contains('isOpen')) {
        container.style.height = `${container.scrollHeight}px`;
      } else {
        container.style.height = 0;
      }
    })
  });

  const firstContainer = document.querySelector('.Documents-container');
  firstContainer.style.height = `${firstContainer.scrollHeight}px`;
}
