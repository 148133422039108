// Libs
import Alpine from 'alpinejs';

// Components
import './mobileDetect';
import '../views/components/Navbar/Navbar';
import '../views/components/NavbarMobile/NavbarMobile';
import '../views/components/News/News';
import '../views/components/NavImage/NavImage';
import '../views/components/PostGallery/PostGallery';
import '../views/components/TeamList/TeamList';
import '../views/components/Timeline/Timeline';
import '../views/components/Documents/Documents';
import './smoothScroll';
import './gallery'; // fslightbox
// import './fadeIn'; // Simple fade in
// import './parallax'; // Simple prallax with Rellax

window.Alpine = Alpine;

Alpine.start();
