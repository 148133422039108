document
  .querySelectorAll('a[href^="#"]')
  .forEach(trigger => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      const hash = e.target.getAttribute('href');
      const target = document.querySelector(hash);

      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    })
  });
