import Glide  from '@glidejs/glide';

const sliders = document.querySelectorAll('.News-categorySlider');
const categoryButtons = document.querySelectorAll('.News-categoryButton');

const glides = [];

if (sliders && categoryButtons) {
  sliders.forEach((wrapper) => {

    new Glide(wrapper.querySelector('.glide'), {
      perView: 3.5,
      gap: 20,
      breakpoints: {
        1250: { gap: 30, perView: 2.5 },
        768: { gap: 20, perView: 1.1 },
      }
    }).mount();
  });

  categoryButtons.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      const category = btn.getAttribute('data-category');

      categoryButtons.forEach((b) => {
        b.classList.remove('isActive');
      });

      btn.classList.add('isActive');

      sliders.forEach((slider) => {
        const sCategory = slider.getAttribute('data-category');

        if (sCategory === category) {
          slider.classList.add('isVisible');
        } else {
          slider.classList.remove('isVisible');
        }
      })
    });
  });
}

